export default {
  methods: {
    submit(url, formData, callback) {
      var self = this;

      $('#loader-wrapper').show();
      $.ajax({
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function(data) {
          if(data.errors){

          $('#loader-wrapper').hide();
          }
          if(callback){
            callback();
          }else{
            self.errors.clear();
            if (data.redirect) {
              location.href = data.redirect;
            } else if (data.errors) {
              for (var field in data.errors) {
                for (var i = 0; i < data.errors[field].length; i++) {
                  self.$validator.errors.add(field, data.errors[field][i], 'server');
                }
              }
            } else {
              location.reload();
            }
          }
        },
        error: function(error) {
          console.log('error');
        }
      })
    }
  }
}
