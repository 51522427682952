import serverValidation from '../mixins/server-validation';

import VeeValidate from 'vee-validate';


Vue.use(VeeValidate, {locale: 'ru'});
Vue.config.devtools = true

new Vue({
    delimiters: ["${", "}"],
    el: "#client-support-page",
    mixins: [serverValidation],
    data: {
        csrf: csrf
    },
    mounted() {
        this.flagsFunc();

        //Disable old feedback
        // this.getOrderDonePopupInfo();
        this.loginFlag();

        $(document).on('click', '.support_btn', function(){
            $.fancybox.close();
            $.fancybox.open({
                src: "#client-support-page",
                type: "inline",
                opts: {}
            });
        });
    },
    created() {
        $('.fieldset__error').hide();
    },
    methods: {
        validateBeforeSubmit(e, config = {}) {
            e.preventDefault();
            this.$validator.validateAll().then(result => {
                if (result) {
                    var formElement = e.composedPath()[0];
                    var formData = new FormData(formElement);
                    formData.append("csrfmiddlewaretoken", this.csrf);
                    var url = $(formElement).attr("action");
                    this.submit(url, formData, function () {
                        $.fancybox.close();
                        formElement.reset();
                        if (!config.noSuccess) {
                            try {
                                $.fancybox.open({
                                    src: "#call_success",
                                    type: "inline",
                                    opts: {}
                                });
                                $('[name="email"], [name="text"]').val('');
                                setTimeout(function () {
                                    $('#loader-wrapper').hide();
                                    $('.fieldset__error').hide();
                                    $.fancybox.close();
                                }, 2000);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    });
                } else {
                    $('.fieldset__error').show();
                }
            });
        },
        flagsFunc() {
            try {
                // func from https://авто-подбор.рф
                $(document).ready(function () {
                    $(".flags .selected").on("click", function () {
                        var list = $(this)
                            .closest(".flags")
                            .find(".lists");
                        if (list.hasClass("active")) {
                            list.removeClass("active").slideUp();
                        } else {
                            list.addClass("active").slideDown();
                        }
                    });
                    $(".flags .lists").on("click", "li", function () {
                        var place = $(this).attr("data-placeholder");
                        var mask = $(this).attr("data-mask");
                        var code = $(this).attr("data-iso-code");
                        $(this)
                            .closest(".flags")
                            .find(".selected .flag")
                            .attr("class", "flag")
                            .addClass(code);
                        $(this)
                            .closest(".control")
                            .find('input[name="tel"]')
                            .attr("placeholder", place)
                            .mask(mask);
                        $(this)
                            .closest(".flags")
                            .find(".lists")
                            .removeClass("active")
                            .slideUp();
                    });
                });
            } catch (e) {
                console.log(e);
            }
        },
        getOrderDonePopupInfo() {
            var self = this;
            var popup = $('#signupServiceRating');

            if (popup.length > 0) {
                try {

                    if ($('.rating-starts').length) {
                        
                        $(document).on('change', '.rating-starts input[name="rating"]', function (e) {
                            var value = parseFloat($(e.target).val());
                            var inputReviewHolder = $('#review_text');
                            $('.btn_raiting').removeClass('disabled');
                            $('.raiting_msg').remove();

                            if (value <= 3) {
                                inputReviewHolder.attr('placeholder', 'Расскажите что мы можем улучшить в нашем сервисе. Мы ценим каждый отзыв от нашего клиента.')
                            } else {
                                inputReviewHolder.attr('placeholder', 'Напишите Ваш отзыв.')
                            }
                        });
                    }

                    $(document).on('click', '.btn_raiting', function(){
                        $('.btn_raiting').addClass('disabled');
                    })

                    $.ajax({
                        type: "POST",
                        url: "/order/api/get_order_done_popup_info/",
                        data: {csrfmiddlewaretoken: self.csrf}
                    })
                    .done(function (response) {

                        if (response["name"]) {
                            Object.keys(response).map(key => {
                                var value = response[key];
                                popup.find('input[name="' + key + '"]').val(value)
                            })
                           
                            //localStorage.setItem('signupServiceStation',true)
                        }
                        $.fancybox.open({src: '#signupServiceRating'});
                        $('#signupServiceRating').fadeIn(0);
                    })
                } catch (error) {
                    console.log('getOrderDonePopupInfo error', error)
                }
            }
        },
        loginFlag(){
            const PHONE_LIST_ITEM = '.form__block-list-item[data-iso-code="ru"]';
            const DEF_MASK = $(PHONE_LIST_ITEM).attr('data-tel-mask');
            const DEF_PHONE_PLACEHOLDER = $(PHONE_LIST_ITEM).attr('data-placeholder');
            const DEF_PHONE_FLAG = $(PHONE_LIST_ITEM + ' img').attr('src');
        
            function inputCounries() {
                $.mask.definitions['d'] = "[0-9]";
                $.mask.definitions['e'] = "[0-7 9]";
        
                const CONTAINER = '.form__input-block_countries';
                const LIST = '.form__block-list';
                const LIST_ITEM = '.form__block-list-item';
                const IMAGE_CONTAINER = '.form__block-select-icon';
                const INPUT = '.form__input_tel-mask';
                const LABEL = '.form__label';
        
                $(document).on('click', IMAGE_CONTAINER, function () {
                    let imageContainer = $(this);
                    let container = imageContainer.closest(CONTAINER);
                    let list = container.find(LIST);
        
                    list.slideToggle();
                });
        
                $(document).on('click', LIST_ITEM, function (e) {
                    let listItem = $(this);
                    let place = listItem.attr('data-placeholder');
                    let mask = listItem.attr('data-tel-mask');
                    let image = listItem.find('img').attr('src');
                    let container = listItem.closest(CONTAINER);
                    let input = container.find(INPUT);
                    let list = container.find(LIST);
                    let label = container.find(LABEL);
                    let imageContainer = container.find(IMAGE_CONTAINER);
                    
                    imageContainer.find('img').attr('src', image);
                    input.val('');
                    input.attr('placeholder', place).mask(mask);
                    input.focus();
                    list.slideUp();
                    inputFocus(CONTAINER, INPUT, label);
                });
        
                $(document).on('click touch', function (event) {
                    if (!$(CONTAINER).is(event.target) && $(CONTAINER).has(event.target).length === 0 && $(LIST).is(":visible")) $(LIST).slideUp();
                });
            }
        
            function inputLabel(){
                const LABEL = '.form__label';
                const INPUT = '.form__input';
                const CONTAINER = '.form__input-block';
                const SELECT_ICON = '.form__block-select-icon';
        
                $(document).on('click', LABEL, function(){
                    inputFocus(CONTAINER, INPUT, $(this));
                });
        
                $(document).on('blur', INPUT, function(){
                    inputBlur(CONTAINER, LABEL, SELECT_ICON, $(this));
                });
        
                // $(LABEL).each(function(_, label){
                //     const container = $(label).closest(CONTAINER);
                //     const input = container.find(INPUT);
        
                //     if(input.val().length > 0){
                //         inputFocus(CONTAINER, INPUT, $(label), false);
                //     }else{
                //         inputBlur(CONTAINER, LABEL, SELECT_ICON, input);
                //     }
                // });
            }
        
            function inputFocus(container, input, current){
                const curLabel = current;
                const curContainer = curLabel.closest(container);
                const curInput = curContainer.find(input);
                const placeholder = curInput.attr('placeholder');
        
                curLabel.addClass('form__label_focused');
                $(curInput).addClass('form__input_focused');

                if(curInput.hasClass('form__input_tel-mask') && curInput.attr('placeholder') === undefined){
                    if(placeholder === undefined){
                        curInput.attr('placeholder', DEF_PHONE_PLACEHOLDER).mask(DEF_MASK);
                    }else{
                        curInput.attr('placeholder', placeholder).mask(placeholder);
                    }
                }
            }
        
            function inputBlur(container, label, icon, current){
                const curInput = current;
                const curContainer = curInput.closest(container);
                const curLabel = curContainer.find(label);
                const IMAGE = $(icon).find('img');
        
                if(!curInput.val().length > 0 || curInput.val().includes('_')){
                    $(curLabel).removeClass('form__label_focused');
                    curInput.removeClass('form__input_focused');
                    curInput.removeAttr('placeholder');
        
                    if(curInput.val().includes('_')){
                        IMAGE.attr('src', DEF_PHONE_FLAG)
                    }
                }
            }
        
            inputCounries();
            inputLabel();

            $(document).on('click', '#password_show_id', function(){
                let idPassInput = $('#id_password');
    
                $(this).prop('checked') ? idPassInput.attr('type', 'text') : idPassInput.attr('type', 'password')
            });
        }
    }
});




